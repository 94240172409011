@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    .list_item_title{
        @apply font-bold flex
    }

    .list_item_content{
        @apply font-medium flex
    }

    .mark-yellow-500 {
        margin: 0 -0.4em;
        padding: 0.1em 0.4em;
        border-radius: 0.8em 0.3em;
        background: transparent;
        background-image: linear-gradient(
          to right,
          rgba(255, 225, 0, 0.3),
          rgba(255, 225, 0, 0.5) 4%,
          rgba(255, 225, 0, 0.6)
        );
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
    }

    .mark-yellow-400 {
        margin: 0 -0.4em;
        padding: 0.1em 0.4em;
        border-radius: 0.8em 0.3em;
        background: transparent;
        background-image: linear-gradient(
          to right,
          rgba(255, 225, 0, 0.4),
          rgba(255, 225, 0, 0.7) 4%,
          rgba(255, 225, 0, 0.3)
        );
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
    }
}

#vanilla-toast-container {
    text-align: center;
    top: 30px;
    left: 0px;
    right: 0px;
    position: fixed;
  }
  
  #vanilla-toast {
    display: none;
    cursor: pointer;
    padding: 15px;
    border-radius: 15px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
  
    background: rgba(0, 0, 0, 0.76);
    color: #ffffff;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1rem;
  }
  
  #vanilla-toast-text {
    display: inline;
  }
  
  #vanilla-toast-close-button {
    display: none;
    margin-left: 15px;
    font-size: 15px;
  }
  
  #vanilla-toast.success {
    background: rgba(92, 184, 92, 0.76);
  }
  
  #vanilla-toast.info {
    background: rgba(91, 192, 222, 0.76);
  }
  
  #vanilla-toast.warning {
    background: rgba(236, 151, 31, 0.76);
  }
  
  #vanilla-toast.error {
    background: rgba(201, 48, 44, 0.76);
  }
